import React from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import StarsOutlinedIcon from "@mui/icons-material/StarsOutlined";
import adsimg from "../Images/Rectangle 1249 (2).png";
import Content from "./Content";

function Sidebar() {
  return (
    <>
      <section className="sidebar pb-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-2 col-md-3 col-lg-3 menu h-100 pb-5">
              <ul className="ps-0 mt-3">
                <li className="d-flex">
                  <HomeOutlinedIcon className="me-2" />
                  <span>Home</span>
                </li>
                <li className="d-flex">
                  <CalendarMonthOutlinedIcon className="me-2" />
                  <span>Events</span>
                </li>
                <li className="d-flex">
                  <PeopleAltOutlinedIcon className="me-2" />
                  <span>Connection</span>
                </li>
                <li className="d-flex">
                  <SaveAsOutlinedIcon className="me-2" />
                  <span>Articles</span>
                </li>
                <li className="d-flex">
                  <StarsOutlinedIcon className="me-2" />
                  <span>Showcase</span>
                </li>
              </ul>
              <p className="text-center ads pt-3">Google ads</p>
              <img src={adsimg} alt="ads" className="img-fluid" loading="lazy"/>
            </div>
            <div className="col-10 col-md-9 col-lg-9 content pt-5">
              <Content />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Sidebar;
