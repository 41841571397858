import React from "react";
import "./Style/Forget.css";
import { Form } from "react-bootstrap";

function Forgetpassword() {
  return (
    <>
      <section className="forget-password">
        <div className="container">
          <div className="row">
            <div className="forget-form m-auto mt-5">
              <h1 className="text-center">Forgot Password</h1>
              <p className="text-center">
                Please enter your contact details to connect.
              </p>
              <form className="">
                <div className="input d-grid">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="name@compagny.com"
                    className="email mt-1"
                    name="email"
                  />
                </div>
                <button className="submit-btn mt-3">Submit</button>
                <p className="forget-up mt-2">Already have an account? Login</p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Forgetpassword;
