import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import user from "../Images/Ellipse 18.png";
import { Avatar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

function Header() {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const handleToggle = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg user-nav pt-3 pb-3">
        <div className="container">
          <Avatar alt="user profile" src={user} />
          <a className="navbar-brand ms-2" href=" ">
            Legal Beets
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleToggle}
          >
            <span className="navbar-toggler-icon">
            {isNavbarOpen ? (
            <CloseIcon style={{ fontSize: "25px" }} /> // Icon when navbar is open
          ) : (
            <MenuIcon style={{ fontSize: "25px" }} /> // Icon when navbar is closed
          )}
            </span>
          </button>
          <div
            className={`collapse navbar-collapse justify-content-end ${isNavbarOpen ? "show" : ""} `}
            id="navbarSupportedContent"
          >
            <div className="navbar-nav mb-2 mb-lg-0">
              <form className="d-flex align-items-center ps-3">
                <SearchIcon style={{ color: "#e1e3e7", fontSize: "20px" }} />
                <input
                  type="text"
                  placeholder="Search"
                  className="search-input ms-1"
                />
              </form>
              <div className="notification d-flex align-items-center">
                <NotificationsNoneIcon style={{ color: "#8A0C53" }} />
                <p className="mb-0">Notifications</p>
              </div>
              <div className="user d-flex align-items-center">
                <Avatar alt="user profile" src={user} />
                <div className="user-detail ms-2">
                  <p className="user-name mb-0">John Deere</p>
                  <p className="user-role mb-0">User</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
