import React from "react";
import "./Style/Signup.css";
import google from "../Images/flat-color-icons_google.png";
import { Form } from "react-bootstrap";
function Signup() {
  return (
    <>
      <section className="signup">
        <div className="container">
          <div className="row">
            <div className="signup-form m-auto mt-5">
              <h1 className="text-center">Sign Up</h1>
              <p className="text-center">
                Please enter your contact details to connect.
              </p>
              <form className="">
                <div className="input d-grid">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="name@compagny.com"
                    className="email "
                    name="email"
                  />
                </div>
                <div className="input d-grid mt-2">
                  <Form.Label>Phone no</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="12345-12345"
                    className="number "
                    name="number"
                  />
                </div>
                <div className="input d-grid mt-2">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="******"
                    className="email "
                    name="email"
                  />
                </div>
                <div className="input d-grid mt-2">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="******"
                    className="Confirm Password "
                    name="Confirm Password"
                  />
                </div>
                <div className="remmember mt-2 d-flex">
                  <Form.Check className="checkbox me-1" />
                  <Form.Label>Remember me</Form.Label>
                </div>
                <button className="signup-btn mt-3">Sign Up</button>
                <br />
                <button className="signup-google mt-2">
                  <img src={google} alt="google" className="me-2" />
                  Log in with Google
                </button>
                <p className="sign-up mt-2">Already have an account? Login</p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Signup;
