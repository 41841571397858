import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
function Footer() {
  return (
    <>
      <section className="footer ">
        <div className="container">
          <div className="row">
            <div className="d-flex justify-content-between align-items-center mb-4 pt-3 footer-logo">
              <h2>Legal Beets</h2>
              <ul className="d-flex justify-content-between align-items-center">
                <li>Home</li>
                <li>Blog</li>
                <li>About us</li>
                <li>Contact us</li>
                <li>Privacy Policy</li>
              </ul>
            </div>
            <form className="d-flex justify-content-between align-items-center">
              <h2>
                Subscribe to our news letter to get
                <br /> latest updates and news
              </h2>
              <div className="d-flex form-input">
                <input type="text" placeholder="Enter your Email" />
                <button className="btn ms-3">Subscribe</button>
              </div>
            </form>
            <div className="d-flex justify-content-between align-items-center text-white mt-3 footer-icon">
              <p className="phone mb-0">Finstreet 118 2561 Fintown</p>
              <div className="social-icon d-flex">
                <FacebookIcon />
                <TwitterIcon />
                <InstagramIcon />
                <LinkedInIcon />
              </div>
            </div>
            <div className="text-white phone">
              <a href="mailto:Hello@finsweet.com">Hello@finsweet.com </a>
              <a href="tel:020 7993 2905"> 020 7993 2905</a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
