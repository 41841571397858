import React from "react";
import post from "../Images/white-concrete-building-1838640.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function Mostliked() {
  return (
    <>
      <section className="most-liked ">
        <div className="container mt-5">
          <div className="row g-md-5">
            <div className="col-12 col-lg-8">
              <h2 className="mb-4">Most Liked Post</h2>
              <div className="card">
                <div className="card-body">
                  <img
                    src={post}
                    className="card-img-top"
                    alt="Most Liked Post"
                    loading="lazy"
                  />
                  <p className="author mt-3">
                    By <span style={{ color: "#8A0C53" }}>John Doe </span>| May
                    23, 2022
                  </p>
                  <h5 className="card-title mb-5">
                    Lorem ipsum dolor sit amet, consectetur tt
                  </h5>
                  <p className="card-text">
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore
                    <br /> eu fugiat nulla pariatur. Excepteur sint occaecat
                    cupidatat non proident.
                  </p>
                  <button className="btn read-btn">
                    Read More
                    <ChevronRightIcon />
                  </button>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4">
              <div className="d-flex justify-content-between align-items-center post-text">
                <h5 className="latest-post">Latest Posts</h5>
                <h5 className="view-all">View All</h5>
              </div>
              <ul className="list-unstyled mt-3">
                {[...Array(4)].map((_, i) => (
                  <li className="mb-2 p-3 ps-4" key={i}>
                    <p className="author mt-2 mb-0">
                      By <span style={{ color: "#8A0C53" }}>John Doe </span> |
                      Aug 23, 2021
                    </p>
                    <a href="/" className="post-title">
                      8 Figma design systems that you can download for free
                      today.
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Mostliked;
