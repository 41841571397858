import React from "react";
import "./Style/Login.css";
import google from "../Images/flat-color-icons_google.png"
import { Form } from "react-bootstrap";

function Login() {
  return (
    <>
      <section className="login">
        <div className="container">
          <div className="row">
            <div className="login-form w-40 m-auto mt-5">
              <h1 className="text-center mt-4">Login</h1>
              <p className="text-center">
                Please enter your contact details to connect.
              </p>
              <form className="">
                <div className="input d-grid">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="name@compagny.com"
                    className="email mt-1"
                    name="email"
                  />
                </div>
                <div className="input d-grid mt-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="******"
                    className="email mt-1"
                    name="email"
                  />
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <div className="remmember d-flex">
                    <Form.Check className="checkbox me-1" />
                    <Form.Label>Remember me</Form.Label>
                  </div>
                  <p>Forgot password</p>
                </div>
                <button className="login-btn">Log in</button>
                <br />
                <button className="login-google mt-2">
                <img src={google} alt="google" className="me-2" loading="lazy"/>Log in with Google
                </button>
                <p className="sign-up mt-2">
                  Don’t have an account ? Sign up here
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
