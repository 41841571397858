import React from "react";

function Joinnow() {
  return (
    <>
      <section className="join-now">
        <div className="container">
          <div className="row text-center pt-5 pb-5">
            <h2>
              Join our team to be a part <br />
              of our story
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing
              <br /> elit, sed do eiusmod tempor incididunt.
            </p>
            <button className="btn">Join Now</button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Joinnow;
