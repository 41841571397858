import React from 'react'
import "../Userprofile/Style/Userprofile.css";
import Header from '../Userprofile/Header'
import Userbanner from '../Userprofile/Userbanner'
import Posts from '../Userprofile/Posts'
import Footer from '../Home/Footer'

function UserprofileRoute() {
  return (
    <>
        <Header/>
        <Userbanner/>
        <Posts/>
        <Footer/>
    </>
  )
}

export default UserprofileRoute