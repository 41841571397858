import React from "react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import articleimg from "../Images/Rectangle 1249 (1).png";
import authorimg from "../Images/Ellipse 18.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function Topvoted() {
  const articles = [
    {
      title: "10 Things No One Told me About Design Systems",
      author: "Diana Wolosin",
      category: "Design Systems in Indeed Design",
      description:
        "This UX Designer learned the hard way but you don’t have to.",
      date: "Aug 16, 2024",
      likes: "3.5k",
      comments: "234",
      imageUrl: [articleimg], // Placeholder image
      authorimg: [authorimg],
    },
    {
      title: "10 Things No One Told me About Design Systems",
      author: "Diana Wolosin",
      category: "Design Systems in Indeed Design",
      description:
        "This UX Designer learned the hard way but you don’t have to.",
      date: "Aug 16, 2024",
      likes: "3.5k",
      comments: "234",
      imageUrl: [articleimg], // Placeholder image
      authorimg: [authorimg],
    },
    {
      title: "10 Things No One Told me About Design Systems",
      author: "Diana Wolosin",
      category: "Design Systems in Indeed Design",
      description:
        "This UX Designer learned the hard way but you don’t have to.",
      date: "Aug 16, 2024",
      likes: "3.5k",
      comments: "234",
      imageUrl: [articleimg], // Placeholder image
      authorimg: [authorimg],
    },
    {
      title: "10 Things No One Told me About Design Systems",
      author: "Diana Wolosin",
      category: "Design Systems in Indeed Design",
      description:
        "This UX Designer learned the hard way but you don’t have to.",
      date: "Aug 16, 2024",
      likes: "3.5k",
      comments: "234",
      imageUrl: [articleimg], // Placeholder image
      authorimg: [authorimg],
    },
    {
      title: "10 Things No One Told me About Design Systems",
      author: "Diana Wolosin",
      category: "Design Systems in Indeed Design",
      description:
        "This UX Designer learned the hard way but you don’t have to.",
      date: "Aug 16, 2024",
      likes: "3.5k",
      comments: "234",
      imageUrl: [articleimg], // Placeholder image
      authorimg: [authorimg],
    },
    {
      title: "10 Things No One Told me About Design Systems",
      author: "Diana Wolosin",
      category: "Design Systems in Indeed Design",
      description:
        "This UX Designer learned the hard way but you don’t have to.",
      date: "Aug 16, 2024",
      likes: "3.5k",
      comments: "234",
      imageUrl: [articleimg], // Placeholder image
      authorimg: [authorimg],
    },
    // Add more articles if needed
  ];
  return (
    <>
      <section className="topvoted">
        <div className="container ">
          <h3 className="text-center ">Top Voted Articles</h3>
          <p className="text-center top-voted-para">
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat
            <br /> nulla pariatur. Excepteur sint occaecat cupidatat non
            proident.
          </p>

          <div className="row g-md-3">
            {articles.map((article, index) => (
              <div className="col-12 col-lg-6" key={index}>
                <div className="card p-4 ">
                  <div className="d-flex article-top">
                    <img
                      src={article.imageUrl}
                      alt="Article Thumbnail"
                      className="img-fluid me-3 article-thumbnail"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                      loading="lazy"
                    />
                    <div>
                      <div className="d-flex article-author">
                        <img
                          src={article.authorimg}
                          alt="author img"
                          loading="lazy"
                        />
                        <p className="mb-1 ms-2 author-name">
                          {article.author} | {article.category}
                        </p>
                      </div>
                      <h6 className="mt-2">{article.title}</h6>
                    </div>
                  </div>
                  <p className=" article-desc">{article.description}</p>
                  <div className="d-flex justify-content-between align-items-center  ">
                    <div className="article-date">
                      <span className="me-3 ">{article.date}</span>
                      <ThumbUpIcon
                        className="me-2"
                        style={{
                          fontSize: "14px",
                        }}
                      />{" "}
                      {article.likes}
                      <ModeCommentIcon
                        className="ms-3 me-2"
                        style={{
                          fontSize: "14px",
                        }}
                      />
                      {article.comments}
                    </div>
                    <div className="dropdown">
                      <button className="btn dots p-0">&#x2026;</button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="text-center mt-4">
            <button className="btn show-btn">
              Show More
              <ChevronRightIcon />
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Topvoted;
