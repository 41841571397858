import React from "react";
import Form from "react-bootstrap/Form";
import user from "../Images/Ellipse 18.png";
import postimg from "../Images/Rectangle 1257.png";
import InsertPhotoSharpIcon from "@mui/icons-material/InsertPhotoSharp";
import SmartDisplaySharpIcon from "@mui/icons-material/SmartDisplaySharp";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import ShareIcon from "@mui/icons-material/Share";
import ChevronRightSharpIcon from "@mui/icons-material/ChevronRightSharp";
import PublishSharpIcon from "@mui/icons-material/PublishSharp";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";

function Content() {
  const post = [
    {
      author: "Christine Aguilera",
      description:
        "Just  verified our first law firm. welcome to the family! #LegalBeets",
      date: "Published by john doe | 22 apr 2023",
      likes: "3.5k",
      comments: "234",
      share: "234",
      authorimg: [user],
      postimg: [postimg],
    },
    {
      author: "Christine Aguilera",
      description:
        "Just  verified our first law firm. welcome to the family! #LegalBeets",
      date: "Published by john doe | 22 apr 2023",
      likes: "3.5k",
      comments: "234",
      share: "234",
      authorimg: [user],
    },
    {
      author: "Christine Aguilera",
      description:
        "Just  verified our first law firm. welcome to the family! #LegalBeets",
      date: "Published by john doe | 22 apr 2023",
      likes: "3.5k",
      comments: "234",
      share: "234",
      authorimg: [user],
    },
    {
      author: "Christine Aguilera",
      description:
        "Just  verified our first law firm. welcome to the family! #LegalBeets",
      date: "Published by john doe | 22 apr 2023",
      likes: "3.5k",
      comments: "234",
      share: "234",
      authorimg: [user],
    },
    {
      author: "Christine Aguilera",
      description:
        "Just  verified our first law firm. welcome to the family! #LegalBeets",
      date: "Published by john doe | 22 apr 2023",
      likes: "3.5k",
      comments: "234",
      share: "234",
      authorimg: [user],
    },
    {
      author: "Christine Aguilera",
      description:
        "Just  verified our first law firm. welcome to the family! #LegalBeets",
      date: "Published by john doe | 22 apr 2023",
      likes: "3.5k",
      comments: "234",
      share: "234",
      authorimg: [user],
    },
  ];

  const events = [
    {
      userimg: [user],
      username: "Legal Beets",
      title: "Legal Insights Meetup",
      address: "Grand Hall, Chicago IL",
      date: "Sat, Apr 22, 2023 at 5 pm",
    },
    {
      userimg: [user],
      username: "Legal Beets",
      title: "Legal Insights Meetup",
      address: "Grand Hall, Chicago IL",
      date: "Sat, Apr 22, 2023 at 5 pm",
    },
    {
      userimg: [user],
      username: "Legal Beets",
      title: "Legal Insights Meetup",
      address: "Grand Hall, Chicago IL",
      date: "Sat, Apr 22, 2023 at 5 pm",
    },
  ];
  return (
    <>
      <div className="ps-4 pe-4 main-content">
        <div className="row">
          <div className="col-12 col-lg-7">
            {/* -------post textarea content-------- */}
            <div className="post-textarea p-3">
              <div className="user-name d-flex align-items-center">
                <img src={user} alt="user" loading="lazy" />
                <p className="mb-0 ms-2">Jhon Doe</p>
              </div>
              <Form.Control
                as="textarea"
                placeholder="Type here..."
                rows={5}
                className="w-100 p-1 mt-2"
              />
              <div className="d-flex justify-content-between mt-3 media-container">
                <div className="add-media d-flex align-items-center ">
                  <div className="d-flex align-items-center">
                    <InsertPhotoSharpIcon
                      style={{ color: "#8A0C53", fontSize: "18px" }}
                    />
                    <p className="mb-0 ms-1">Image</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <SmartDisplaySharpIcon
                      className="ms-3"
                      style={{ color: "#8A0C53", fontSize: "18px" }}
                    />
                    <p className="mb-0 ms-1">Video</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <DescriptionOutlinedIcon
                      className="ms-3"
                      style={{ color: "#8A0C53", fontSize: "18px" }}
                    />
                    <p className="mb-0 ms-1 ">Files</p>
                  </div>
                </div>
                <button className="post-btn">Post</button>
              </div>
            </div>
            {/* -----------Social feed content----------- */}
            <div className="feed mt-4">
              <div className="d-flex align-items-center justify-content-between">
                <h2>Social Feed</h2>
                <p>
                  Short By
                  <ArrowDropDownSharpIcon />
                </p>
              </div>
              <div className="social-posts">
                <div className="row gy-3">
                  {post.map((post, index) => (
                    <div className="col-12" key={index}>
                      <div className="card p-2 ps-4 pe-4">
                        <div className="d-flex post-top">
                          <img
                            src={post.authorimg}
                            alt="author img"
                            loading="lazy"
                            className="mt-2"
                          />
                          <div className="post-author">
                            <p className="mb-0 ms-2 author-name">
                              {post.author}
                            </p>
                            <p className="ms-2 post-date">{post.date}</p>
                          </div>
                        </div>
                        <p className="post-desc mt-3">{post.description}</p>
                        {post.postimg && (
                          <img
                            src={post.postimg}
                            alt="post img"
                            className="img-fluid mt-3"
                            loading="lazy"
                          />
                        )}

                        <div className="post-view d-flex align-items-center  mt-3">
                          <ThumbUpIcon
                            className="me-2"
                            style={{
                              fontSize: "14px",
                            }}
                          />
                          <span>{post.likes}</span>
                          <ModeCommentIcon
                            className="ms-3 me-2"
                            style={{
                              fontSize: "14px",
                            }}
                          />
                          <span>{post.comments}</span>

                          <ShareIcon
                            className="ms-3 me-2"
                            style={{
                              fontSize: "14px",
                            }}
                          />
                          <span>{post.share}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-5 events">
            <h2>Upcoming Events</h2>
            {/* --------apply events --------*/}
            <div className="apply-events">
              {events.map((event, index) => (
                <div className="card mb-4">
                  <div className="card-body p-4">
                    <div className="user-img d-flex align-items-center">
                      <img src={event.userimg} alt="user" loading="lazy" />
                      <p className="mb-0 ms-2">{event.username}</p>
                    </div>
                    <h5 className="card-title">{event.title}</h5>
                    <p className="card-text mb-1">{event.address}</p>
                    <p className="card-date">{event.date}</p>
                    <div className="intrested-btn">
                      <button className="intrested">
                        Interested
                        <ChevronRightSharpIcon />
                      </button>
                      <button className="upload ms-2">
                        <PublishSharpIcon />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* ----------Connection-------- */}
            <div className="connection ">
              <h2>Connections</h2>
              <div className="connection-list d-flex align-items-center p-4 mb-3">
                <img src={user} alt="user" loading="lazy" />
                <p className="mb-0 ms-2">John Doe</p>
              </div>
              <div className="connection-list d-flex align-items-center p-4 mb-3">
                <img src={user} alt="user" loading="lazy" />
                <p className="mb-0 ms-2">John Doe</p>
              </div>
              <div className="connection-list d-flex align-items-center p-4 mb-3">
                <img src={user} alt="user" loading="lazy" />
                <p className="mb-0 ms-2">John Doe</p>
              </div>
              <div className="connection-list d-flex align-items-center p-4 mb-3">
                <img src={user} alt="user" loading="lazy" />
                <p className="mb-0 ms-2">John Doe</p>
              </div>
            </div>
            <div className="jobs">
              <h2>All Jobs</h2>
              <div className="apply-events">
                <div className="card mb-4">
                  <div className="card-body p-4">
                    <div className="user-img d-flex align-items-center">
                      <img src={user} alt="user" loading="lazy" />
                      <h5 className="card-title ms-2">
                        Senior car herder phoenix Ris ing, Inc
                      </h5>
                    </div>
                    <p className="card-text mb-2 mt-2">
                      Los Angeles, CA (Remote)
                    </p>
                    <div className="intrested-btn">
                      <button className="intrested">
                        Apply Now
                        <ChevronRightSharpIcon />
                      </button>
                      <button className="upload ms-2">
                        <BookmarkBorderOutlinedIcon />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card mb-4">
                  <div className="card-body p-4">
                    <div className="user-img d-flex align-items-center">
                      <img src={user} alt="user" loading="lazy" />
                      <h5 className="card-title ms-2">
                        Senior car herder phoenix Ris ing, Inc
                      </h5>
                    </div>
                    <p className="card-text mb-2 mt-2">
                      Los Angeles, CA (Remote)
                    </p>
                    <div className="intrested-btn">
                      <button className="intrested">
                        Apply Now
                        <ChevronRightSharpIcon />
                      </button>
                      <button className="upload ms-2">
                        <BookmarkBorderOutlinedIcon />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Content;
