import React from "react";
import adsimg from "../Images/Rectangle 1249.png";
import adsimg2 from "../Images/Rectangle 1251.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SendIcon from "@mui/icons-material/Send";

function Herosection() {
  return (
    <>
      <section className="hero-section">
        <div className="container">
          <div className="row pt-5 pb-4 g-md-5">
            <div className="col-12 col-lg-8 ">
              <span className="text-white">Google ads</span>
              <img src={adsimg} alt="ads" className="w-100" loading="lazy" />
              <h1 className="text-white mt-3">
                We publish articles that were not selected for the law review.
              </h1>
              <p className="text-white">
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum <br />
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat
                <br /> non proident.
              </p>
              <button className="read-more">
                Read More
                <ChevronRightIcon />
              </button>
            </div>
            <div className="col-12 col-lg-4 right-text">
              <div className="d-flex align-items-center">
                <h2 className="latest-job text-white">Latest Jobs</h2>
                <div className="vr ms-3 me-2" />
                <h3 className="filter text-white">
                  <FilterAltIcon />
                  Filter Location
                </h3>
              </div>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-0">Data Analyst specialist</h5>
                  <p className="card-text mb-0">Rocketship, Inc.</p>
                  <p className="card-text mb-0">Berlin - On- Site</p>
                  <div className="d-flex justify-content-end">
                    <button className="btn apply-btn">
                      <SendIcon
                        style={{
                          backgroundColor: "#8A0C53",
                          color: "white",
                          borderRadius: "50px",
                          padding: "5px",
                          marginRight: "5px",
                        }}
                      />
                      Apply Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="card mt-2">
                <div className="card-body">
                  <h5 className="card-title mb-0">Data Analyst specialist</h5>
                  <p className="card-text mb-0">Rocketship, Inc.</p>
                  <p className="card-text mb-0">Berlin - On- Site</p>
                  <div className="d-flex justify-content-end">
                    <button className="btn apply-btn">
                      <SendIcon
                        style={{
                          backgroundColor: "#8A0C53",
                          color: "white",
                          borderRadius: "50px",
                          padding: "5px",
                          marginRight: "5px",
                        }}
                      />
                      Apply Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="card mt-2">
                <div className="card-body">
                  <h5 className="card-title mb-0">Data Analyst specialist</h5>
                  <p className="card-text mb-0">Rocketship, Inc.</p>
                  <p className="card-text mb-0">Berlin - On- Site</p>
                  <div className="d-flex justify-content-end">
                    <button className="btn apply-btn">
                      <SendIcon
                        style={{
                          backgroundColor: "#8A0C53",
                          color: "white",
                          borderRadius: "50px",
                          padding: "5px",
                          marginRight: "5px",
                        }}
                      />
                      Apply Now
                    </button>
                  </div>
                </div>
              </div>
              <button className="btn find-btn w-100 mt-2">
                Find Jobs
                <ChevronRightIcon />
              </button>
              <span className="text-white">Google ads</span>
              <img src={adsimg2} alt="ads" className="w-100" loading="lazy" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Herosection;
