import HomeRoute from "./Components/Routes/HomeRoute";
import UserprofileRoute from "./Components/Routes/UserprofileRoute";
import DashboardRoute from "./Components/Routes/DashboardRoute";
import Login from "./Components/Login/Login"; 
import Signup from "./Components/Signup/Signup"; 
import Forgetpassword from "./Components/Forgetpassword/Forgetpassword"; 
import { createBrowserRouter, RouterProvider } from "react-router-dom";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeRoute/>,
    },
    {
      path: "/user-profile",
      element: <UserprofileRoute/>,
    },
    {
      path: "/dashboard",
      element: <DashboardRoute/>,
    },
    {
      path: "/login",
      element: <Login/>,
    },
    {
      path: "/Signup",
      element: <Signup/>,
    },
    {
      path: "/forget-password",
      element: <Forgetpassword/>,
    },
  ]);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
