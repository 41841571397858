import React from "react";

function Event() {
  const events = [
    {
      title: "Event 1",
      description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
      date: "21 Aug",
    },
    {
      title: "Event 2",
      description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
      date: "25 Aug",
    },
    {
      title: "Event 3",
      description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
      date: "28 Aug",
    },
    {
      title: "Event 4",
      description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
      date: "29 Aug",
    },
  ];

  return (
    <>
      <section className="event">
        <div className="container">
          <div className="row">
            <h2 className="text-center">Upcoming Events</h2>
            {events.map((event, index) => (
              <div className="col-6 col-sm-4 col-lg-3 mt-5" key={index}>
                <div className="card">
                  <div className="card-body">
                    <p className="date">{event.date}</p>
                    <h5 className="card-title">{event.title}</h5>
                    <p className="card-text mb-0">{event.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Event;
