import React from "react";
import "../Home/Style/Home.css";
import Navbar from "../Home/Navbar";
import Herosection from "../Home/Herosection";
import Mostliked from "../Home/Mostliked";
import Topvoted from "../Home/Topvoted";
import Aboutmission from "../Home/Aboutmission";
import Event from "../Home/Event";
import Whystarted from "../Home/Whystarted";
import Testimonial from "../Home/Testimonial";
import Joinnow from "../Home/Joinnow";
import Footer from "../Home/Footer";

function HomeRoute() {
  return (
    <>
      <Navbar />
      <Herosection />
      <Mostliked />
      <Topvoted />
      <Aboutmission />
      <Event />
      <Whystarted />
      <Testimonial />
      <Joinnow />
      <Footer />
    </>
  );
}

export default HomeRoute;
