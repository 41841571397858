import React from "react";
import cover from "../Images/Rectangle 1258.png";
import user from "../Images/Ellipse 21.png";
import { ChevronRight } from "@mui/icons-material";

function Userbanner() {
  return (
    <>
      <section className="user-banner pt-4">
        <div className="container">
          <div className="row user-cover">
            <img src={cover} alt="cover-img" className="w-100" />
            <button>
              Edit Cover Image <ChevronRight />
            </button>
          </div>
          <div className="user-profile">
            <div className="d-flex justify-content-between align-items-center">
              <img
                alt="user profile"
                src={user}
                sx={{ width: 150, height: 150 }}
                className="profile-pic"
              />
              <button className="edit-profile me-3">
                Edit Profile <ChevronRight />
              </button>
            </div>
            <div className="user-details">
              <p className="user-name mb-0">
                Christine Aguilera @ Harvard Law Student
              </p>
              <p className="user-role mb-0">Defending Integrity . Future JD</p>
            </div>
          </div>
          <div className="tab pt-4">
            <ul className="d-flex align-items-center">
              <li>Options</li>
              <li>Articles</li>
              <li>Resume</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Userbanner;
