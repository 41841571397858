import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';

function Navbar() {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const handleToggle = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg home-nav">
        <div className="container">
          <a className="navbar-brand" href=" ">
            Legal Beets
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleToggle}
          >
            <span className="navbar-toggler-icon">
            {isNavbarOpen ? (
            <CloseIcon style={{ fontSize: "25px", color: "white" }} /> // Icon when navbar is open
          ) : (
            <MenuIcon style={{ fontSize: "25px", color: "white" }} /> // Icon when navbar is closed
          )}
            </span>
          </button>
          <div
            className={`collapse navbar-collapse justify-content-end ${isNavbarOpen ? "show" : ""} `}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-3">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href=" ">
                  Home
                </a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link" href=" ">
                  Social Feed
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " href=" ">
                  Job Site
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " href=" ">
                  Article Site
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " href=" ">
                  Upcomming Events
                </a>
              </li>
            </ul>
            <form className="d-flex align-items-center me-2">
              <SearchIcon style={{ color: "white", fontSize: "20px" }} />
              <button className="btn text-white search-btn ps-0">Search</button>
            </form>
            <button className="login-btn">Login/Signup</button>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
