import React from "react";
import bgimg from "../Images/close-up-photography-of-man-wearing-sunglasses-1212984.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function Whystarted() {
  return (
    <>
      <section className="why-started">
        <div className="container">
          <div className="row">
            <img src={bgimg} alt="bgimg" className="bg-img" loading="lazy" />
            <div className="why-text">
              <span>Why we started </span>
              <h2>
                It started out as a simple idea and evolved into our passion
              </h2>
              <p className="mt-3 mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip.
              </p>
              <button className="btn">
                Discover our story
                <ChevronRightIcon />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Whystarted;
