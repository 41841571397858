import React from "react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import authorimg from "../Images/Ellipse 18.png";
import ShareIcon from "@mui/icons-material/Share";
import gallery from "../Images/Rectangle 1262.png";
import gallery1 from "../Images/Rectangle 1263.png";
import gallery2 from "../Images/Rectangle 1264.png";
import gallery3 from "../Images/Rectangle 1265.png";
import gallery4 from "../Images/Rectangle 1266.png";
import gallery5 from "../Images/Rectangle 1267.png";
import gallery6 from "../Images/Rectangle 1268.png";
import gallery7 from "../Images/Rectangle 1269.png";
import gallery8 from "../Images/Rectangle 1270.png";

function Posts() {
  const post = [
    {
      author: "Christine Aguilera",
      shortdesc: "Check it out in the Articles sections!",
      description:
        "Excited to share my latest publication on comparative law. Dive deep into the intricacies of international legal systems and let’s discuss the evolving landscape of global regulations.",
      date: "Published by john doe | 22 apr 2023",
      likes: "3.5k",
      comments: "234",
      share: "234",
      authorimg: [authorimg],
    },
    {
      author: "Christine Aguilera",
      shortdesc: "Check it out in the Articles sections!",
      description:
        "Excited to share my latest publication on comparative law. Dive deep into the intricacies of international legal systems and let’s discuss the evolving landscape of global regulations.",
      date: "Published by john doe | 22 apr 2023",
      likes: "3.5k",
      comments: "234",
      share: "234",
      authorimg: [authorimg],
    },
    {
      author: "Christine Aguilera",
      shortdesc: "Check it out in the Articles sections!",
      description:
        "Excited to share my latest publication on comparative law. Dive deep into the intricacies of international legal systems and let’s discuss the evolving landscape of global regulations.",
      date: "Published by john doe | 22 apr 2023",
      likes: "3.5k",
      comments: "234",
      share: "234",
      authorimg: [authorimg],
    },
    {
      author: "Christine Aguilera",
      shortdesc: "Check it out in the Articles sections!",
      description:
        "Excited to share my latest publication on comparative law. Dive deep into the intricacies of international legal systems and let’s discuss the evolving landscape of global regulations.",
      date: "Published by john doe | 22 apr 2023",
      likes: "3.5k",
      comments: "234",
      share: "234",
      authorimg: [authorimg],
    },
  ];
  return (
    <>
      <section className="posts pt-4 pb-5">
        <div className="container ">
          <div className="row">
            <div className="col-12 col-lg-8 ">
              <div className="left-posts">
                <h2>Posts</h2>
                <div className="row gy-3">
                  {post.map((post, index) => (
                    <div className="col-12" key={index}>
                      <div className="card p-2 ps-4 pe-4">
                        <div className="d-flex post-top">
                          <img
                            src={post.authorimg}
                            alt="author img"
                            loading="lazy"
                            className="mt-2"
                          />
                          <div className="post-author">
                            <p className="mb-0 ms-2 author-name">
                              {post.author}
                            </p>
                            <p className="ms-2 post-date">{post.date}</p>
                          </div>
                        </div>
                        <p className=" post-desc">{post.description}</p>
                        <p className="short-desc">{post.shortdesc}</p>

                        <div className="post-view d-flex align-items-center ">
                          <ThumbUpIcon
                            className="me-2"
                            style={{
                              fontSize: "14px",
                            }}
                          />
                          <span>{post.likes}</span>
                          <ModeCommentIcon
                            className="ms-3 me-2"
                            style={{
                              fontSize: "14px",
                            }}
                          />
                          <span>{post.comments}</span>

                          <ShareIcon
                            className="ms-3 me-2"
                            style={{
                              fontSize: "14px",
                            }}
                          />
                          <span>{post.share}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 ">
              <div className="right-posts">
                <h2>Photos</h2>
                <div className="row g-1 ">
                  <div className="col-12 col-lg-4">
                    <img src={gallery} alt="gallery" className="w-100" />
                  </div>
                  <div className="col-12 col-lg-4">
                    <img src={gallery1} alt="gallery" className="w-100" />
                  </div>
                  <div className="col-12 col-lg-4">
                    <img src={gallery2} alt="gallery" className="w-100" />
                  </div>
                  <div className="col-12 col-lg-4">
                    <img src={gallery3} alt="gallery" className="w-100" />
                  </div>
                  <div className="col-12 col-lg-4">
                    <img src={gallery4} alt="gallery" className="w-100" />
                  </div>
                  <div className="col-12 col-lg-4">
                    <img src={gallery5} alt="gallery" className="w-100" />
                  </div>
                  <div className="col-12 col-lg-4">
                    <img src={gallery6} alt="gallery" className="w-100" />
                  </div>
                  <div className="col-12 col-lg-4">
                    <img src={gallery7} alt="gallery" className="w-100" />
                  </div>
                  <div className="col-12 col-lg-4">
                    <img src={gallery8} alt="gallery" className="w-100" />
                  </div>
                </div>
              </div>
              <div className="right-connection mt-2 p-3">
                <h2>Connections</h2>
                <div className="connect-list d-flex align-items-center">
                  <img src={authorimg} alt="profile" />
                  <p className="mb-0 ms-2">John Doe</p>
                </div>
                <div className="connect-list d-flex align-items-center">
                  <img src={authorimg} alt="profile" />
                  <p className="mb-0 ms-2">John Doe</p>
                </div>
                <div className="connect-list d-flex align-items-center">
                  <img src={authorimg} alt="profile" />
                  <p className="mb-0 ms-2">John Doe</p>
                </div>
                <div className="connect-list d-flex align-items-center">
                  <img src={authorimg} alt="profile" />
                  <p className="mb-0 ms-2">John Doe</p>
                </div>
                <div className="connect-list d-flex align-items-center">
                  <img src={authorimg} alt="profile" />
                  <p className="mb-0 ms-2">John Doe</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Posts;
