import React from "react";
import profile from "../Images/Profile picture.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function Testimonial() {
  return (
    <>
      <section className="testimonial">
        <div className="container">
          <div className="row g-md-5">
            <div className="col-12 col-lg-6 left-border">
              <span>TESTIMONIALS</span>
              <h2>
                What people say
                <br /> about our blog
              </h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur <br />
                adipiscing elit, sed do eiusmod tempor.
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <div
                id="carouselExampleControls"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <p className="carousel-text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <div className="d-flex">
                      <img src={profile} alt="profile" loading="lazy" />
                      <div className="ms-3">
                        <p className="mb-0">
                          <strong>Jonathan Vallem</strong>
                        </p>
                        <p className="mb-0 place">New york, USA</p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <p className="carousel-text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <div className="d-flex">
                      <img src={profile} alt="profile" loading="lazy" />
                      <div className="ms-3">
                        <p className="mb-0">
                          <strong>Jonathan Vallem</strong>
                        </p>
                        <p className="mb-0 place">New york, USA</p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <p className="carousel-text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <div className="d-flex">
                      <img src={profile} alt="profile" loading="lazy" />
                      <div className="ms-3">
                        <p className="mb-0">
                          <strong>Jonathan Vallem</strong>
                        </p>
                        <p className="mb-0 place">New york, USA</p>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <ArrowBackIcon style={{ fontSize: "28px", color: "#000" }} />
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <ArrowForwardIcon
                    style={{ fontSize: "28px", color: "#000" }}
                  />
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonial;
