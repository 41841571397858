import React from "react";
import "../Dashboard/Style/Dashboard.css";
import Header from "../Userprofile/Header";
import Sidebar from "../Dashboard/Sidebar";
import Footer from "../Home/Footer";

function DashboardRoute() {
  return (
    <>
      <Header />
      <Sidebar/>
      <Footer/>
    </>
  );
}

export default DashboardRoute;
